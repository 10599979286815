export const API_ENDPOINTS = {
  getAllUserByCompany: "User/GetAllUserByCompany",
  loanDetailsByUser: "Loan/GetLoanDetailsByUser",
  getNotificationByUserId: "Notification/GetNotificationByUserId",
  getTransactionByUserId: "transaction/GetTransactionByUserId",
  getEmiCalenderByUser: "Loan/GetEmiCalenderByUser",
  getLoanByCompany: "Loan/GetAllByCompany",
  getUserByCompanyLoan: "User/GetAllUserBYCompanyLoan",
  getProfile: "User/GetProfile",
  verifyMobile: "User/VerifyMobile",
  verifyOTP: "User/VerifyOTP",
  verifyEmail: "User/VerifyEmail",
  updateCompanyProfile: "Admin/UpdateCompanyProfile",
  updateCompanyProfileByUser: "Admin/UpdateCompanyProfileByUser",
  uploadFile: "uploadfile",
  updateCompany: "Admin/UpdateCompany",
  getBank: "Bank/GetBank",
  getAllBank: "Admin/GetAllBank",
  getAllBranch: "Admin/GetAllBranch",
  addBank: "Bank/AddBank",
};

// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
// ** Axios Imports
import axios from "axios";
import {
  ContactUs,
  // authApiEndPoint,
  baseurl,
  cmsApiEndPoint,
} from "../../../configs/serverApiConfig";

export const VerifyMobileNumber = createAsyncThunk(
  "/User/VerifyMobile",
  async (mobileNumber, thunkAPI) => {
    const apiurl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${apiurl}User/VerifyMobile`,
        {
          mobile: mobileNumber, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response, 'Calenderdata')
      return { verifyMobileNumber: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const VerifyOTPEmail = createAsyncThunk(
  "/User/VerifyOTP",
  async (OTPDATA, thunkAPI) => {
    const apiurl = process.env.REACT_APP_API_BASEURL;

    try {
      const response = await axios.post(`${apiurl}User/VerifyOTP`, OTPDATA);
      // console.log(response, 'Calenderdata')
      return { verifyOTPEmail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const VerifyEmail = createAsyncThunk(
  "/User/VerifyEmail",
  async (emailId, thunkAPI) => {
    const apiurl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${apiurl}User/VerifyEmail`,
        {
          email: emailId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // console.log(response, 'Calenderdata')
      return { verifyEmail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const VerifyUserEmail = createAsyncThunk(
  "/User/VerifyUserEmail",
  async (emailId, thunkAPI) => {
    const apiurl = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${apiurl}User/VerifyUserEmail`,
        {
          email: emailId, // Pass the row.id as a parameter
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { verifyUserEmail: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const VerifyOTPMobile = createAsyncThunk(
  "/User/VerifyMobileOTP",
  async (OTPDATA, thunkAPI) => {
    const apiurl = process.env.REACT_APP_API_BASEURL;

    try {
      const response = await axios.post(`${apiurl}User/VerifyOTP`, OTPDATA);
      // console.log(response, 'Calenderdata')
      return { verifyOTPMobile: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const ResetPasswordUser = createAsyncThunk(
  "/User/ResetPasswordUser",
  async (PasswordData, thunkAPI) => {
    const apiurl = process.env.REACT_APP_API_BASEURL;

    try {
      const response = await axios.post(
        `${apiurl}User/ResetPassword`,
        PasswordData
      );
      // console.log(response, 'Calenderdata')
      return { resetPasswordUserData: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const GetBlog = createAsyncThunk("/User/GetBlog", async (thunkAPI) => {
  // const API = process.env.REACT_APP_API_BASEURL;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await axios.get(
      `${baseurl?.adminBaseURL}${cmsApiEndPoint?.GetBlog}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return { getBlog: response?.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const GetBlogBySlug = createAsyncThunk(
  "/User/GetBlogBySlug",
  async (data) => {
    console.log(data?.slug, "data");
    // const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminBaseURL}${cmsApiEndPoint?.GetBlogBySlug}/${data?.slug}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getBlogBySlug: response?.data };
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data);
      console.log("error",error)
    }
  }
);
export const GetCMSBySlug = createAsyncThunk(
  "/CMS/GetCMSBySlug",
  async (DataOfSlug) => {
    // const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminBaseURL}${cmsApiEndPoint?.GetCMSBySlug}/${DataOfSlug}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getCMSBySlug: response?.data };
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetLendingPartner = createAsyncThunk(
  "/CMS/GetLendingPartner",
  async (thunkAPI) => {
    // const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminBaseURL}${cmsApiEndPoint?.GetLendingPartner}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getLendingPartner: response?.data };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const GetNews = createAsyncThunk("/User/GetNews", async (thunkAPI) => {
  // const API = process.env.REACT_APP_API_BASEURL;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const response = await axios.get(
      `${baseurl?.adminBaseURL}${cmsApiEndPoint?.GetNews}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    return { getNews: response?.data };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const GetNewsBySlug = createAsyncThunk(
  "/User/GetNewsBySlug",
  async (data) => {
    console.log(data?.slug, "data");
    // const API = process.env.REACT_APP_API_BASEURL;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `${baseurl?.adminBaseURL}${cmsApiEndPoint?.GetNewsBySlug}/${data?.slug}`,

        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { getNewsBySlug: response?.data };
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data);
      console.log("error",error)
    }
  }
);
export const AddContactUs = createAsyncThunk(
  "/User/ContactUs",
  async (data) => {
    console.log(data?.slug, "data");

     let accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios.post(
        `${baseurl?.adminBaseURL}${ContactUs.submitContactUs}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return { addContactUs: response?.data };
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data);
      console.log("error",error)
    }
  }
);
export const resetAsyncThunks = createAction(
  "homepageAPIdata/resetAsyncThunks"
);
export const HomePageDataSlice = createSlice({
  name: "homepageAPIdata",
  initialState: {
    verifyMobileNumber: [],
    verifyEmail: [],
    verifyOTPEmail: [],
    verifyOTPMobile: [],
    verifyUserEmail: [],
    resetPasswordUserData: [],
    getNews: [],
    isLoadingNews: false,
    isLoadingBlog: false,
    OTPdata: [],
    LoginPage: [],
    LoginPopUp: false,
    SignUpPopUpClose: false,
    getBlog: [],
    getBlogBySlug: [],
    getCMSBySlug: [],
    getLendingPartner: [],
    getNewsBySlug: [],
    addContactUs: [],
  },
  reducers: {
    setOTPData: (state, action) => {
      state.OTPdata = action.payload;
    },
    setLoginPage: (state, action) => {
      state.LoginPage = action.payload;
    },
    setLoginPopUp: (state, action) => {
      state.LoginPopUp = action.payload;
    },
    setSignUpPopUpClose: (state, action) => {
      state.SignUpPopUpClose = action.payload;
    },
    resetGetCMS: (state) => {
      state.getCMSBySlug = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(VerifyMobileNumber.fulfilled, (state, action) => {
        state.verifyMobileNumber = action.payload.verifyMobileNumber;
      })
      .addCase(VerifyEmail.fulfilled, (state, action) => {
        state.verifyEmail = action.payload.verifyEmail;
      })
      .addCase(VerifyOTPEmail.fulfilled, (state, action) => {
        state.verifyOTPEmail = action.payload.verifyOTPEmail;
      })
      .addCase(VerifyOTPMobile.fulfilled, (state, action) => {
        state.verifyOTPMobile = action.payload.verifyOTPMobile;
      })
      .addCase(VerifyUserEmail.fulfilled, (state, action) => {
        state.verifyUserEmail = action.payload.verifyUserEmail;
      })
      .addCase(GetBlog.pending, (state) => {
        state.isLoadingBlog = true;
      })
      .addCase(GetBlog.fulfilled, (state, action) => {
        state.isLoadingBlog = false;
        state.getBlog = action.payload.getBlog;
      })
      .addCase(GetBlog.rejected, (state) => {
        state.isLoadingBlog = false;
      })
      .addCase(GetNews.pending, (state) => {
        state.isLoadingNews = true;
      })
      .addCase(GetNews.fulfilled, (state, action) => {
        state.getNews = action.payload.getNews;
        state.isLoadingNews = false;
      })
      .addCase(GetNews.rejected, (state) => {
        state.isLoadingNews = false;
      })
      .addCase(GetBlogBySlug.fulfilled, (state, action) => {
        state.getBlogBySlug = action.payload.getBlogBySlug;
      })
      .addCase(GetNewsBySlug.fulfilled, (state, action) => {
        state.getNewsBySlug = action.payload.getNewsBySlug;
      })
      .addCase(GetCMSBySlug.fulfilled, (state, action) => {
        state.getCMSBySlug = action.payload.getCMSBySlug;
      })
      .addCase(GetLendingPartner.fulfilled, (state, action) => {
        state.getLendingPartner = action.payload.getLendingPartner;
      })
      .addCase(ResetPasswordUser.fulfilled, (state, action) => {
        state.resetPasswordUserData = action.payload.resetPasswordUserData;
      })
      .addCase(resetAsyncThunks, (state) => {
        state.verifyMobileNumber = [];
        state.verifyEmail = [];
        state.verifyOTPEmail = [];
        state.verifyOTPMobile = [];
        state.verifyUserEmail = [];
      });
  },
});
export const { setOTPData } = HomePageDataSlice.actions;
export const { setLoginPage } = HomePageDataSlice.actions;
export const { setLoginPopUp } = HomePageDataSlice.actions;
export const { setSignUpPopUpClose } = HomePageDataSlice.actions;
export const { resetGetCMS } = HomePageDataSlice.actions;
export default HomePageDataSlice.reducer;

// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'

const ConfigJwt =()=>{
    const { jwt } = useJwt({})
    console.log('JWT Config:', jwt.jwtConfig);
    return  jwt.jwtConfig;
}


export default ConfigJwt



// import useJwt from '@src/@core/auth/jwt/useJwt'

// const JwtEmptyConfig = ()=>{

//     const { jwt } = useJwt({})
//     return jwt.jwtConfig;

// }

// export default JwtEmptyConfig;

import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./reportAction";

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    commissionReportData: [],
    getEmiByReport: [],
    getLoanByReport: [],
    loading: false,
    error: null,
  },
  reducers: {
    reset(state) {
      for (const key in state) {
        state[key] = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.GetLoanByReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.GetLoanByReport.fulfilled, (state, action) => {
        state.loading = false;

        state.getLoanByReport = action.payload.getLoanByReport;
      })
      .addCase(actions.GetLoanByReport.rejected, (state) => {
        state.loading = false;
      })
      .addCase(actions.getCommissionReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.getCommissionReport.fulfilled, (state, action) => {
        state.commissionReportData = action.payload.data;
        state.loading = false;
      })
      .addCase(actions.getCommissionReport.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(actions.GetEmiByReport.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.GetEmiByReport.fulfilled, (state, action) => {
        state.loading = false;
        state.getEmiByReport = action.payload.getEmiByReport;
      })
      .addCase(actions.GetEmiByReport.rejected, (state) => {
        state.loading = false;
      })
      .addCase(actions.GetCommission.pending, (state) => {
        state.loading = true;
      })
      .addCase(actions.GetCommission.fulfilled, (state, action) => {
        state.loading = false;
        state.getCommission = action.payload.getCommission;
      })
      .addCase(actions.GetCommission.rejected, (state) => {
        state.loading = false;
      })
      .addCase(actions.getCompanyCommissionReport.pending,(state)=>{
        state.loading=true;
      })
      .addCase(actions.getCompanyCommissionReport.fulfilled,(state,action)=>{
        state.loading=false;
        state.getCompanyCommission = action.payload.getCompanyCommission;
      })
      .addCase(actions.getCompanyCommissionReport.rejected,(state)=>{
          state.loading = false;
      })
  },
});
export const { reset } = reportSlice.actions;

export default reportSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import * as actions from "./authAction";
import ConfigJwt from "@src/auth/jwt/useJwt"; // Update the path as needed


// ** UseJWT import to get config
// import useJwt from "@src/auth/jwt/useJwt";


const initialUser = () => {
  const item = window.localStorage.getItem("userData");
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {};
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    verifyMobileNumber: [],
    verifyEmail: [],
    verifyOTPEmail: [],
    verifyOTPMobile: [],
    changePassword: [],
    userData: initialUser(),
    isLoading: false,
    isLoadingVerifyMobileNumber: false,
  },
  reducers: {
    handleLogin: (state, action) => {
      let config = ConfigJwt();
      console.log("config:-",config)

      state.userData = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        action.payload.accessToken
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        action.payload.refreshToken
      );
    },

    handleLogout: (state) => {
      
      let config = ConfigJwt();
      console.log("config:-",config)

      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);
    },

    reset(state) {
      const initialState = {
        verifyMobileNumber: [],
        verifyEmail: [],
        verifyOTPEmail: [],
        verifyOTPMobile: [],
        changePassword: [],
        userData: initialUser(),
        isLoading: false,
        isLoadingVerifyMobileNumber: false,
      };

      // Reset all keys except the specified ones
      for (const key in state) {
        if (initialState.hasOwnProperty(key)) {
          state[key] = initialState[key];
        } else {
          state[key] = [];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.VerifyMobileNumber.pending, (state) => {
        state.isLoadingVerifyMobileNumber = true;
      })
      .addCase(actions.VerifyMobileNumber.fulfilled, (state, action) => {
        state.isLoadingVerifyMobileNumber = false;

        state.verifyMobileNumber = action.payload.data;
      })
      .addCase(actions.VerifyMobileNumber.rejected, (state, action) => {
        state.isLoadingVerifyMobileNumber = false;
        state.verifyMobileNumber = action.payload;
      })
      .addCase(actions.VerifyEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.VerifyEmail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verifyEmail = action.payload.data;
      })
      .addCase(actions.VerifyEmail.rejected, (state, action) => {
        state.verifyEmail = action.payload;
        state.isLoading = false;
      })
      .addCase(actions.VerifyOTPEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.VerifyOTPEmail.fulfilled, (state, action) => {
        state.verifyOTPEmail = action.payload.data;
        state.isLoading = false;
      })
      .addCase(actions.VerifyOTPEmail.rejected, (state, action) => {
        state.verifyOTPEmail = action.payload;
      })
      .addCase(actions.VerifyOTPMobile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actions.VerifyOTPMobile.fulfilled, (state, action) => {
        state.verifyOTPMobile = action.payload.data;
        state.isLoading = false;
      })
      .addCase(actions.VerifyOTPMobile.rejected, (state, action) => {
        state.verifyOTPMobile = action.payload;
        state.isLoading = false;
      })
      .addCase(actions.ChangePassword.fulfilled, (state, action) => {
        state.changePassword = action.payload.data;
      })
      .addCase(actions.ChangePassword.rejected, (state, action) => {
        state.changePassword = action.payload.data;
      })
      .addCase(actions.resetAsyncThunksSignUpPage, (state) => {
        state.verifyMobileNumber = [];
        state.verifyEmail = [];
        state.verifyOTPEmail = [];
        state.verifyOTPMobile = [];
      });
  },
});
export const { handleLogin, handleLogout, reset } = authSlice.actions;

export default authSlice.reducer;

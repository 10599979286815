export const accessToken = localStorage.getItem("accessToken");

export const baseurl = {
  authBaseURL: "https://api.moneyplant.finance/v1/auth/",
  customerBaseURL: "https://api.moneyplant.finance/v1/customer/",
  loanBaseURL: "https://api.moneyplant.finance/v1/loan/",
  paymentBaseURL: "https://api.moneyplant.finance/v1/payment/",
  kycBaseURL: "https://api.moneyplant.finance/v1/kyc/",
  adminBaseURL: "https://api.moneyplant.finance/v1/admin/",
  employeeManagementBaseURL: process.env.REACT_APP_API_BASEURL,
  commissionManagementBaseUrl: process.env.REACT_APP_API_BASEURL,
  adminProfileManagementBaseURL: process.env.REACT_APP_API_BASEURL,
};


 //let ip ="192.168.1.6";
// const ip = "13.233.170.187"

// export const baseurl = {
//   authBaseURL: `http://${ip}:8081/v1/auth/`,
//   customerBaseURL: `http://${ip}:8082/v1/customer/`,
//   loanBaseURL: `http://${ip}:8083/v1/loan/`,
//   paymentBaseURL: `http://${ip}:8084/v1/payment/`,
//   kycBaseURL: `http://${ip}:8085/v1/kyc/`,
//   adminBaseURL: `http://${ip}:8086/v1/admin/`,
//   employeeManagementBaseURL: process.env.REACT_APP_API_BASEURL,
//   commissionManagementBaseUrl: process.env.REACT_APP_API_BASEURL,
//   adminProfileManagementBaseURL: process.env.REACT_APP_API_BASEURL,
// };

// export const baseurl = {
//   authBaseURL: "http://13.233.170.187:8081/v1/auth/",
//   customerBaseURL: "http://13.233.170.187:8082/v1/customer/",
//   loanBaseURL: "http://13.233.170.187:8083/v1/loan/",
//   paymentBaseURL: "http://13.233.170.187:8084/v1/payment/",
//   kycBaseURL: "http://13.233.170.187:8085/v1/kyc/",
//   adminBaseURL: "http://13.233.170.187:8086/v1/admin/",
//   employeeManagementBaseURL: process.env.REACT_APP_API_BASEURL,
//   commissionManagementBaseUrl: process.env.REACT_APP_API_BASEURL,
//   adminProfileManagementBaseURL: process.env.REACT_APP_API_BASEURL,
// };

export const authApiEndPoint = {
  VerifyMobile: "company-verify-mobile-signup",
  VerifyOTP: "company-mobile-verify-otp",
  VerifyEmail: "company-verify-email",
  companySignUp: "company-signup",
  companySignIn: "company-signin",
  // companySignIn: "Admin/SignInCompany",
  companyCheck: "company-check-gst-pan-cin",
  changePassword: "company/change-password",
  ForgotPassword: "company/forgot-password",
  ForgotPasswordWithTOken: "company/forgot-password-with-token",
};

export const bankApiEndPoint = {
  GetBank: "Bank/GetBank",
  GetAllBank: "Admin/GetAllBank",
  getAllBranch: "Admin/GetAllBranch",
  AddBank: "Bank/AddBank",
};
export const companyApiEndPoint = {
  UpdateCompanyProfile: "Admin/UpdateCompanyProfile",
  UpdateCompanyProfileByUser: "Admin/UpdateCompanyProfileByUser",
  UpdateCompany: "Admin/UpdateCompany",
  UserList: "company-user-list",
  createUser: "company-user/create",
  editUser: "company-user/update",
};

export const notificationApiEndPoint = {
  GetNotificationByUserId: "Notification/GetNotificationByUserId",
};
export const employeeApiEndpoint = {
  GetAllUserByCompany: "User/GetAllUserByCompany",
  GetAllUserBYCompanyLoan: "User/GetAllUserBYCompanyLoan",
  // GetProfile: "User/GetProfile",
  // GetProfile: "company-profile",
};
export const companyProfileApiEndPoint = {
  GetProfile: "company-profile",
  UpdateProfile: "company-update",
};
// /v1/loan/company/loan-list/pending

export const loanApiEndPoint = {
  GetLoanList: "company/loan-list",
  GetLoanPendingList: "company/loan-list/pending",
  GetLoanDetailsByUserID: "company/loan-details",
  GetLoanDetailsByUser: "Loan/GetLoanDetailsByUser",
  GetTransactionByUserId: "transaction/GetTransactionByUserId",
  GetEmiCalenderByUser: "Loan/GetEmiCalenderByUser",
  GetAllByCompany: "Loan/GetAllByCompany",
  GetLoanApproveEnd: "company/loan-approve",
  GetLoanRejectEnd: "company/loan-reject",
  GetLoanEMIHistoryEnd: "company/emi-history",
  GetLoanEMICalenderEnd: "emi-calendar",
};

export const reportApiEndPoint = {
  GetCommissionReport: "company/commission-report",
  GetLoanReport: "company/loan-report",
  GetEMIReport: "company/emi-report",
  GetCompanyCommissionReport:"company/commission-report-company"
};

export const dashboardEndPoint = {
  GetDashBoard: "company-dashboard",
};

export const cmsApiEndPoint = {
  GetCMSBySlug: "cms/get-cms-by-slug",
  GetBlog: "blog/list",
  GetNews: "news/list",
  GetBlogBySlug: "blog/get-blog-by-slug",
  GetNewsBySlug: "news/get-news-by-slug",
  GetLendingPartner: "lending-partners/list",
};
export const escrowApiEndPoint = {
  GetBranchData: "company/get-branchdata",
  AddEscrowPayBankAccount: "company/add-escrowpay-bank-account",
  GetEscrowPayStatement: "company/get-escrowpay-statement",
  DepositMoney: "company/get-escrowpay-payment-url",
  GetBank: "company/get-bank",
};
export const ContactUs = {
  submitContactUs: "contact/create",
};
